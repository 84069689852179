@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap&subset=latin-ext);
body {
  margin: 0px;
  padding: 0px;
  font-family: Roboto, sans-serif; }

.product-form .MuiFormControl-root.MuiFormControl-fullWidth {
  margin-bottom: 10px; }
  .product-form .MuiFormControl-root.MuiFormControl-fullWidth.picture {
    display: none; }

